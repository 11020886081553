import React from "react";
import "./spacer.css";

const Spacer = (props) => {
    const { size } = props;

    const sizerRef = React.useRef(null);

    React.useEffect(() => {
        const currentSizer = sizerRef.current;
        currentSizer.style.setProperty("--size", size + "px")
    })

    return (
        <span ref={sizerRef} className={"size"} />
    );
};

export default Spacer;