import { forwardRef, useEffect, useState } from "react";
const EmbedYoutubeChat = () => {
  
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch(`https://goral.dev/api/get_yt_stream_id`)
    .then(response => response.json())
    .then(data => {
      setData(data);
      setIsLoading(false);
    })
    .catch(error => setError(error));
  }, []);
  
  if (isLoading) {
    return <div>Loading...</div>;
  }
  
  const chatUrl = `https://www.youtube.com/live_chat?v=${data.yt_stream_id}&embed_domain=sejm.stream`;
  return (
    <iframe 
      width="500px" 
      height="100%" 
      src={chatUrl} 
      frameBorder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowFullScreen
    />
  );
};

export default EmbedYoutubeChat;