import { useEffect, useRef, useState } from "react";
import Camera from "../components/camera.jsx";
import Footer from "../components/footer.jsx";
import Mirko from "../components/mirko.jsx";
import EmbedYoutubeChat from "../components/ytchat.jsx";
import {Helmet} from "react-helmet";

import Playbar from "../components/playbar/Playbar.jsx";
import Navbar from "../components/navbar/Navbar.jsx";
import "../global.css";
import "./home.css";
import { Container } from "react-bootstrap";

export default function Komisja() {
  const [chatOption, setChatOption] = useState('mirko');





  
  return (
    <Container fluid="true">
      <Helmet>
        <title>▶ 🇵🇱Sejm.Stream | Sejmflix | Transmisja online | Na żywo</title>
        <meta name="description" content="Oglądaj na żywo wszystkie kamery Sejmu i nie przegap żadnego ważnego wydarzenia!" />
        <meta name="keywords" content="Sejm, Polska, polityka, obrady, ustawy, transmisja, Hołownia, posiedzienie, stream, kamery, 4 kamery, na zywo, itv, strim" />
        <meta name="author" content="Sejm Stream" />
        <link rel="canonical" href="https://sejm.stream" />
        <meta property="og:url" content="https://sejm.stream" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Sejm.Stream | Sejmflix | Transmisja online | Na żywo" />
        <meta property="og:description" content="Oglądaj na żywo wszystkie kamery Sejmu i nie przegap żadnego ważnego wydarzenia!" />
        <meta property="og:image" content="https://sejm.stream/sejmstream.png" />
        <meta name="og:image:width" content="1200" />
        <meta name="og:image:height" content="630" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content="Sejm.Stream | Sejmflix | Transmisja online | Na żywo" />
        <meta property="twitter:site" content="@sejmstream" />
        <meta property="twitter:description" content="Oglądaj na żywo wszystkie kamery Sejmu i nie przegap żadnego ważnego wydarzenia!" />
        <meta property="twitter:image" content="https://sejm.stream/sejmstream.png" />
        <meta property="twitter:type" content="website" />
        <meta property="twitter:creator" content="@sejmstream" />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="630" />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="google-adsense-account" content="ca-pub-7081284432268717" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#db202c" />
        <meta name="msapplication-TileColor" content="#db202c" />
        <meta name="theme-color" content="#ffffff" />
    </Helmet>
      <Navbar />
      {chatOption === 'mirko' ? <Mirko /> : chatOption === 'yt' ? <EmbedYoutubeChat streamId="34H1XIjnfKM" /> : null}
        <Playbar></Playbar>
      <Footer />
    </Container>
  );
}
