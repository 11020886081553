import { useEffect, useRef, useState } from "react";
import Camera from "../components/camera.jsx";
import Footer from "../components/footer.jsx";
import Mirko from "../components/mirko.jsx";
import EmbedYoutubeChat from "../components/ytchat.jsx";
import {Helmet} from "react-helmet";

import Playbar from "../components/playbar/Playbar.jsx";
import Navbar from "../components/navbar/Navbar.jsx";
import "../global.css";
import "./home.css";
import { Container } from "react-bootstrap";

export default function Home() {
  // const cameraDefinitions = [
  //   {
  //     name: "ENC01",
  //     desc: "Kamera #1",
  //   },
  //   { name: "ENC30", desc: "Kamera #2" },
  //   { name: "ENC31", desc: "Kamera #3" },
  //   { name: "ENC32", desc: "Kamera #4" },
  // ];
  // const startTime = 713957173000;
  // const stopTime = 713958267000;
  const startTime = null; // 713957173000;
  const stopTime = null; //713958267000;
  
  const [camerasActive, setCamerasActive] = useState(false);
  const [ignoreSeek, setIgnoreSeek] = useState(false);
  const [volume, setVolume] = useState(1);
  const [fullWidth, setfullWidth] = useState(false);

  const [chatOption, setChatOption] = useState('mirko');
  
  
  const [whichToToggle, doToggle] = useState({});
  
  const camerasRef = useRef([]);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch(`https://goral.dev/api/get_cameras`)
    .then(response => response.json())
    .then(data => {
      setData(data);
      setIsLoading(false);
    })
    .catch(error => setError(error));
  }, []);
  useEffect(() => {
    if (camerasActive) {
      setTimeout(() => {
        let keys = Object.keys(camerasRef.current);
        camerasRef.current[keys[0]]?.player.player.unmute();
        camerasRef.current[keys[1]]?.player.player.mute();
        camerasRef.current[keys[2]]?.player.player.mute();
        camerasRef.current[keys[3]]?.player.player.mute();
        for (let cam of keys) {
          camerasRef.current[cam]?.player.player.play();
        }
      }, 1000);
    }
  }, [camerasActive]);
  
  if (isLoading) {
    return <div>Loading...</div>;
  }
  const cameraDefinitions = data.cameras;
  console.log(cameraDefinitions);


  const startCameras = () => {
    setCamerasActive(true);
  };

  const onPause = () => {
    let keys = Object.keys(camerasRef.current);
    for (let cam of keys) {
      camerasRef.current[cam]?.player.player.pause();
    }
  };

  const onPlay = () => {
    let keys = Object.keys(camerasRef.current);
    for (let cam of keys) {
      camerasRef.current[cam]?.player.player.play();
    }
  };

  const onToggle = (which) => {
    let keys = Object.keys(camerasRef.current);
    let toToggle = keys[which];
    let playersToggle = {};
    keys.forEach((cam) => {
      playersToggle[cam] = cam === toToggle ? true : false;
    });
    doToggle(playersToggle);
  }

  const onSeek = (seconds) => {
    return;
    if (ignoreSeek) return;
    setIgnoreSeek(true);
    console.log("seek");
    let keys = Object.keys(camerasRef.current);
    for (let cam of keys) {
      camerasRef.current[cam]?.seekTo(seconds, "seconds");
    }
    setTimeout(() => {
      setIgnoreSeek(false);
    }, 1000);
  };

  const onMute = (muted) => {
    const keys = Object.keys(camerasRef.current);
    for (let cam of keys) {
      camerasRef.current[cam]?.player.player.mute();
    }
    if (muted) camerasRef.current[keys[0]].player.player.mute();
    else camerasRef.current[keys[0]].player.player.unmute();
  };

  const changeVolume = (volume) => {
    const keys = Object.keys(camerasRef.current);
    for (let cam of keys) {
      if (camerasRef.current[cam].player.player.player)
        camerasRef.current[cam].player.player.player.volume = volume;
    }
    setVolume(volume);
  }

  const chatOff = chatOption === 'off' ? "12" : '8';
  const chatOff2 = chatOption === 'off' ? `display="none"` : `display="block"`;


  
  return (
    <Container fluid="true">
      <Helmet>
        <title>▶ 🇵🇱Sejm.Stream | Sejmflix | Transmisja online | Na żywo</title>
        <meta name="description" content="Oglądaj na żywo wszystkie kamery Sejmu i nie przegap żadnego ważnego wydarzenia!" />
        <meta name="keywords" content="Sejm, Polska, polityka, obrady, ustawy, transmisja, Hołownia, posiedzienie, stream, kamery, 4 kamery, na zywo, itv, strim" />
        <meta name="author" content="Sejm Stream" />
        <link rel="canonical" href="https://sejm.stream" />
        <meta property="og:url" content="https://sejm.stream" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Sejm.Stream | Sejmflix | Transmisja online | Na żywo" />
        <meta property="og:description" content="Oglądaj na żywo wszystkie kamery Sejmu i nie przegap żadnego ważnego wydarzenia!" />
        <meta name="og:image" content="https://sejm.stream/sejmstream.png" />
        <meta name="og:image:width" content="1200" />
        <meta name="og:image:height" content="630" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Sejm.Stream | Sejmflix | Transmisja online | Na żywo" />
        <meta name="twitter:site" content="@sejmstream" />
        <meta name="twitter:description" content="Oglądaj na żywo wszystkie kamery Sejmu i nie przegap żadnego ważnego wydarzenia!" />
        <meta name="twitter:image" content="https://sejm.stream/sejmstream.png" />
        <meta name="twitter:type" content="website" />
        <meta name="twitter:creator" content="@sejmstream" />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="630" />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="google-adsense-account" content="ca-pub-7081284432268717" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#db202c" />
        <meta name="msapplication-TileColor" content="#db202c" />
        <meta name="theme-color" content="#ffffff" />
    </Helmet>
      {/* <Header /> */}
      <Navbar />

      {camerasActive ? (
        <div className="col-12 d-flex flex-wrap">
          <div className={`col-12 col-lg-${chatOff} camera-wrapper my-lg-2 `}>
            {cameraDefinitions.map((x,i) => (
              <Camera
              key={i}
                initialCameraName={x.name}
                definitions={cameraDefinitions}
                startTime={startTime}
                stopTime={stopTime}
                muted={true}
                ref={(el) => (camerasRef.current[x.name] = el)}
                togglePlayer={whichToToggle}

                onPause={onPause}
                onPlay={onPlay}
                onSeek={onSeek}
                fullWidth={fullWidth}
                onSourceChanged={(e) => {
                  let time = camerasRef.current[x.name]?.getCurrentTime();
                  let seekTimeout = 0;
                  if (!time) {
                    let keys = Object.keys(camerasRef.current);
                    for (let cam of keys) {
                      let t = camerasRef.current[cam]?.getCurrentTime();
                      if (t) {
                        time = t;
                        // console.log(`Setting time to ${cam} ${t}`);
                        seekTimeout = 500;
                        break;
                      }
                    }
                  }
                  setTimeout(() => {
                    camerasRef.current[x.name]?.player.player.play();
                    setTimeout(() => {
                      if (time) {
                        onSeek(time);
                        // console.log(`Seeking to ${time}`);
                      }
                    }, seekTimeout);
                  }, 1000);
                }}
              />
            ))}
            <div className="col-12">

            <Playbar
              playbarPlay={onPlay}
              playbarPause={onPause}
              playbarToggle={onToggle}
              playbarMute={onMute}
              playbarChatOption={setChatOption}
              playbarFullWidth={setfullWidth}
            />
          <p className="lead fs-5 text-warning mb-2t text-center">
                Źródła materiałów: System Informacyjny Sejmu | iTV Sejm | sejm.gov.pl | api.sejm.gov.pl | YouTube kanał SEJM RP
            </p>
            </div>
          </div>
          <div className={`col-sm-12 col-lg-4 d-flex justify-content-center ${chatOff2}`}>
          {chatOption === 'mirko' ? <Mirko /> : chatOption === 'yt' ? <EmbedYoutubeChat streamId="34H1XIjnfKM" /> : null}

          </div>
        </div>
      ) : (
        <center>
          <button className="btn btn-danger my-5 p-3" onClick={startCameras}>
            Uruchom kamery
          </button>
        </center>
      )}
      <Footer />
    </Container>
  );
}
