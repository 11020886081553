import { forwardRef, useEffect, useState } from "react";

import ReactPlayer from "react-player";
import "./camera.css";
export default forwardRef(function Camera(
  {
    muted,
    onPause,
    onPlay,
    onSeek,
    initialCameraName,
    startTime,
    stopTime,
    definitions,
    onSourceChanged,
    togglePlayer,
    fullWidth
  },
  ref
) {
  const [cameraName, setCameraName] = useState(initialCameraName);
  const [isError, setIsError] = useState(false);
  const [toggleClass, setToggleClass] = useState("display");

  useEffect(() => {
    for (const key in togglePlayer) {
      if (togglePlayer[key] === true) {
        var keyForToggle = togglePlayer[key] === true ? key : null;
      }
      if (toggleClass === "display") {
        cameraName === keyForToggle ? setToggleClass("non-display") : setToggleClass("display");
      } else {
        cameraName === keyForToggle ? setToggleClass("display") : setToggleClass("non-display");
      }
    }
  }, [togglePlayer]);

  const [url, setUrl] = useState("");

  const width = fullWidth ? '12' : '6';

  useEffect(() => {
    updateUrl(initialCameraName);
  }, []);

  const setCamera = (x) => {
    const value = x.target.value;
    setCameraName(value);
    if (value !== "null") {
      onSourceChanged(value);
    }
    updateUrl(value);
    setIsError(false);
  };

  const updateUrl = (cameraName) => {
    const params = new URLSearchParams();
    if (startTime) params.append("startTime", startTime);
    if (stopTime) params.append("stopTime", stopTime);
    const url = `https://r.dcs.redcdn.pl/livehls/o2/sejm/${cameraName}/live.livx/playlist.m3u8?${params.toString()}`;
    setUrl(url);
  };

  return (
    <div className={`${toggleClass} col-md-${width} col-12 camera-player`}>
      <div
        style={{
          backgroundColor: "#1d2125",
          width: "100%",
          padding: "12px",
          borderRadius: "10px 10px 0px 0px",
        }}
      >
        <select onChange={setCamera} value={cameraName} className="w-100">
          <option value={"null"}>Brak</option>
          {definitions.map((x, i) => (
            <option key={i} value={x.name}>
              {x.desc}
            </option>
          ))}
        </select>
      </div>
      {cameraName !== "null" ? (
        isError ? (
          <div className="camera">
            <p className="text-center text-light">Błąd źródła (brak streamu?)</p>
          </div>
        ) : (
          <ReactPlayer
            width={"100%"}
            // height={"400px"}
            height={"100%"}
            // playing={true}
            controls={true}
            ref={ref}
            muted={muted}
            onPlay={onPlay}
            onPause={onPause}
            onSeek={onSeek}
            pip={true}
            // config={lowLatencyMode=false}

            

            // playsinline={true}
            onError={(error, data, hlsInstance, hlsGlobal) => {
              // setIsError(true);
              console.log(error, data, hlsInstance, hlsGlobal);
            }}
            url={url}
            stopOnUnmount={false}
          />
        )
      ) : (
        <div className="camera">
          <p className="text-center text-light">Wybierz źródło</p>
        </div>
      )}
    </div>
  );
});
