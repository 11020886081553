import React from "react";
import "./navbar.css";
import { ReactComponent as Coffee } from "./coffee.svg";
import { ReactComponent as Twitter } from "./twitter.svg";
import { ReactComponent as XIcon } from "./x.svg";



import Button from "../button/Button";
import Spacer from "../Spacer/Spacer";
import FlashingIcon from "./FlashingIcon";


const Navbar = (props) => {
  const { whichActive } = props;

  const whichPushed = 1;

  const coffeLink = "https://buycoffee.to/ilunapogon";
  const twitterLink = "https://twitter.com/SejmStream";


  return (
    <div className="navbar-container">
      <Spacer size={24} />
      <div className="navbar-full">
        <div className="logo">
          <h1>sejm.stream</h1>
        </div>
        <div className="navbar-navigation">
          {/* <Button
                        isOn={whichPushed === 1 ? true : false}
                    >
                        Stream
                    </Button> */}
          {/* <DropdownButton
            as={Button}
            title="Komisje"
          >
            <Dropdown.Item eventKey="1">Komisja śledcza / Wybory - 10:00</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="2">Komisja nadzwyczajna / Minimalne wynagrodzenie - 17:00</Dropdown.Item>

          </DropdownButton> */}
          {/* <a href="/" >
            <Button  type="primary" disabled>
              Posiedzenie sejmu
            <FlashingIcon />
            </Button>
          </a>
          <a href="/komisja" disabled>

            <Button disabled>
              Komisje sejmowe
            </Button>
          </a> */}
          <Button isOn={whichPushed === 2 ? true : false} disabled>
            Kalendarz
          </Button>
          <Button isOn={whichPushed === 3 ? true : false} disabled>
            Info
          </Button>
          <a href={twitterLink} rel="noreferrer" target="_blank">
            <Button
              // icon="yes"
              type="primary"
              isOn={whichPushed === 4 ? true : false}
            >
              <div>
              {/* <Twitter className="twitter-icon"/> */}
                Obserwuj na 𝕏
              </div>
              {/* <XIcon className="twitter-icon"/> */}
            </Button>
            {/* <button type="button" class="button button-text button-mono button-primary bg-warning text-dark w-50">Postaw kawę!</button> */}
          </a>
          <a href={coffeLink} rel="noreferrer" target="_blank">
            <Button
              // icon="yes"
              type="secondary"
              isOn={whichPushed === 4 ? true : false}
            >
              <div>
                <Coffee className="coffee-icon" />
                Postaw kawę!
              </div>
            </Button>
            {/* <button type="button" class="button button-text button-mono button-primary bg-warning text-dark w-50">Postaw kawę!</button> */}
          </a>

        </div>
      </div>
      <Spacer size={24} />
    </div>
  );
};

export default Navbar;
