import React from "react";
import PropTypes from "prop-types";
import 'primeicons/primeicons.css';
import "./button.css";

const Button = (props) => {
    const { type, bistable, isOn, icon, onlyIcon, children, className, id, disabled, onClick,href } = props;

    const [buttonDown, pushDown] = React.useState(false)

    const btnContent = !icon ? children : <>{children}<i className={`pi pi-${icon}`}></i></>;
    const onlyIconClass = onlyIcon ? "button-icon" : "button-text";
    let buttonDownClass = bistable ? (buttonDown ? "button-up" : "button-down") : "button-mono";

    if (!bistable && isOn && !disabled) {
        buttonDownClass = "button-mono-pushed";
    }

    const onClickFunc = () => {
        pushDown(prev => !prev);
        onClick && onClick();
    };

    return (
        <button
            id={id}
            type="button"
            className={["button", `${onlyIconClass}`, `${buttonDownClass}`, `button-${type}`, `${className}`].join(" ")}
            onClick={onClickFunc}
            disabled={disabled}
            href={href}
        >
            {btnContent}
        </button>
    );
};

Button.propTypes = {
    type: PropTypes.oneOf(["primary", "secondary", "tertiary", "warning", "success"]),
    // size: PropTypes.oneOf(["sm", "md", "lg"]),
    bistable: PropTypes.bool,
    isOn: PropTypes.bool,
    icon: PropTypes.string,
    onlyIcon: PropTypes.bool,
    disabled: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    onClick: PropTypes.func,
    className: PropTypes.string,
    id: PropTypes.string,
    href: PropTypes.string
};

Button.defaultProps = {
    type: "primary",
    bistable: false,
    isOn: false,
    // size: "md",
    onlyIcon: false,
    disabled: false,
    className: "",
};

export default Button;