import React from "react";
import "./playbar.css";

import Button from "../button/Button";
import Spacer from "../Spacer/Spacer";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';


const Playbar = (props) => {
    const { playbarPlay, playbarPause, playbarToggle, playbarMute,playbarChatOption,playbarFullWidth } = props;

    const [stop, setStop] = React.useState(false);
    const [mute, setMute] = React.useState(false);
    const [fullWidth, setfullWidth]  = React.useState(false);

    const [chatOption, setChatOption] = React.useState();
    const radios = [
        { name: '#sejm', value: 'mirko' },
        { name: 'YT Chat', value: 'yt' },
        { name: "OFF",value: 'off'}
    ];
    const [radioValue, setRadioValue] = React.useState('mirko');

    console.log(fullWidth);
    return (
        <div className="playbar-container">
            {/* <Spacer size={24} /> */}
            <div className="playbar-full">
                <div className="playbar-left-section">
                    {!stop ?
                        <Button
                            onlyIcon
                            bistable
                            icon="play"
                            onClick={() => {
                                setStop(prev => !prev);
                                playbarPause();
                            }}
                        >
                            <Spacer size={3} />
                        </Button>
                        :
                        <Button
                            onlyIcon
                            bistable
                            icon="pause"
                            onClick={() => {
                                setStop(prev => !prev);
                                playbarPlay();
                            }}
                        />
                    }
                    {!mute ?
                        <Button
                            onlyIcon
                            bistable
                            icon="volume-down"
                            onClick={() => {
                                setMute(prev => !prev);
                                playbarMute(true);
                            }}

                        />
                        :
                        <Button
                            onlyIcon
                            bistable
                            icon="volume-off"
                            onClick={() => {
                                setMute(prev => !prev);
                                playbarMute(false);
                            }}

                        />
                    }
                     {!fullWidth ?
                        <Button
                            onlyIcon
                            className="button-up"
                            icon="arrows-h"
                            onClick={() => {
                                setfullWidth(prev => !prev);
                                playbarFullWidth(true);
                            }}

                        />
                        :
                        <Button
                            onlyIcon
                            className="button-down"


                            icon="arrows-h"
                            onClick={() => {
                                setfullWidth(prev => !prev);
                                playbarFullWidth(false);
                            }}

                        />
                    }
                    <Button
                        onlyIcon
                        bistable
                        onClick={() => {
                            playbarToggle(0);
                        }}
                    >
                        1
                    </Button>
                    <Button
                        onlyIcon
                        bistable
                        onClick={() => {
                            playbarToggle(1);
                        }}
                    >
                        2
                    </Button>
                    <Button
                        onlyIcon
                        bistable
                        onClick={() => {
                            playbarToggle(2);
                        }}
                    >
                        3
                    </Button>
                    <Button
                        onlyIcon
                        bistable
                        onClick={() => {
                            playbarToggle(3);
                        }}
                    >
                        4
                    </Button>
                </div>
                {/* control sound range part */}
                <div className="playbar-right-section">
                    {/* <Button
                        icon="volume-up"
                    /> */}
                    {/* <input type="range" /> */}
                </div>
                <ButtonGroup>
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant={radio.value === 'mirko' ? 'outline-success' : radio.value === 'off' ? 'dark' : 'outline-danger'}
            name="radio"
            value={radio.value}
            checked={chatOption === radio.value}
            onChange={(e) => {
                const newValue = e.currentTarget.value;
                setRadioValue(newValue);
                playbarChatOption(newValue);
                setChatOption(radio.value);

              }}
              
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
            </div>
            {/* <Spacer size={24} /> */}
        </div>
    );
};

export default Playbar;