import React from 'react';
// import App from './App';
import ReactDOM from "react-dom/client";
import Home from './pages/home.jsx';
import Komisja from './pages/komisja.jsx';

// import Mirko from './components/mirko.jsx'

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';


const container = document.getElementById('root');

const router = createBrowserRouter([
  {
    path: "*",
    element: <Home/>
  },
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/komisja",
    element: <Komisja/>,
  },
]);

const root = ReactDOM.createRoot(container);
root.render(
  <>
      <RouterProvider router={router} />
  </>
);
