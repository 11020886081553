import React, { useEffect, useState } from "react";
import axios from "axios";

import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Stack from "react-bootstrap/Stack";

import "./mirko.css";
import { Spinner } from "react-bootstrap";
import dayjs from "dayjs";

function Mirko() {
  const [entries, setEntries] = useState([]);
  let entries_list = [];

  useEffect(() => {
    const intervalId = setInterval(fetchData, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const fetchData = async () => {
    const result = await axios("https://goral.dev/api/get_entries", {
      timeout: 10000,
    })
      .then((response) => {
        for (var i = 0; i < 10; i++) {
          const post = response.data.data[i];
          // console.log(post);
          if (!post.author_avatar)
            post.author_avatar =
              "https://wykop.pl/static/img/svg/avatar-default.svg";
          if (!(entries_list.filter((e) => e.id === post.id).length > 0)) {
            entries_list.push(post);
            setEntries((oldData) => [...oldData, post]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container className="text-white mirko-wrapper overflow-auto px-3 px-md-3 py-2">
      <Row className="chat flex align-content-center flex-column px-2">
        <Row className="w-100 text-center py-2 position-sticky">
          <p>
            <a href="https://wykop.pl/tag/sejm">#sejm</a> z Wykop.pl
          </p>
        </Row>

        {entries?.length > 0 ? (
          entries
            .sort((a, b) => b.id - a.id)
            .slice(0, 10)
            .map((item, i) => (
              <Row
                key={i}
                className="mirko bg-dark my-2 p-2 rounded rounded-1 justify-content-center"
              >
                <Row xs="12">
                  <Col xs="3" md="1" lg="3">
                    <Image
                      src={item.author_avatar}
                      rounded
                      className="shadow align-self-center bg-dark d-inline avatar"
                    />
                  </Col>
                  <Col
                    xs="9"
                    md="11"
                    lg="9"
                    className="d-flex flex-wrap align-items-center"
                  >
                    <Col xs="12" md="6" lg="8">
                      <p className="m-0">{item.author_name}</p>
                    </Col>
                    <Col xs="12" md="6" lg="4">
                      <p className="text-secondary m-0 text-truncate text-md-end text-lg-start">
                        {/* {item.created_at.toString("HH:MM:SS")} */}
                        {dayjs(item.created_at).format("DD.MM HH:mm")}
                      </p>
                    </Col>
                  </Col>
                </Row>
                <Col sm="12" lg="12" className="py-2 align-self-center">
                  <p className="">{item.content}</p>
                </Col>
              </Row>
            ))
        ) : (
          <Container className="d-flex mirko-wrapper align-items-center justify-content-center">
            <Spinner></Spinner>
          </Container>
        )}
      </Row>
    </Container>
  );
}
export default Mirko;
