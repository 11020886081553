export default function Footer() {
  return (
    <div className="">
      <hr className="border border-warning border-1 w-100 my-1" />
<div class="container">
  <div class="row">
    <div class="col-lg-9">
    </div>
    <div class="col-lg-12">
    <div className="text-center w-100">
            <p className="h3 text-white">
                <b style={{color:"#db202c"}}>Sejm.Stream</b> umożliwia oglądanie posiedzień <b>Sejmu RP</b> ze wszystkich możliwych <b>kamer</b> w sali plenarnej.
            </p>
            <p className="fs-5 text-warning">
                Gdzie można oglądać obrady Sejmu?
            </p>
            <p className="fs-6 text-white">
               Transmisje na żywo z obrad sejmu dostępne są na oficjalnej stronie sejmowej, kanale Youtube Sejmu, jednak tylko strona <b style={{color:"#db202c"}}>sejm.stream</b> umożliwia podgląd z <b>4 kamer jednocześnie</b> bez potrzeby zmiany kamer za pomocą przycisków.
            </p>
            <p className="fs-5 text-warning">
                Jakie dodatkowe funkcje posiada <b>sejm.stream</b>?
            </p>
            <p className="fs-6 text-white">
                Oprócz podglądu na <b>prace Sejmu</b> oraz <b>komisje sejmowe</b> jest również dostępny jest wybór czatu na żywo z YT oraz tagu #sejm z serwisu <b>wykop.pl</b>
            </p>
            <center>
            <hr className="border border-warning border-1 w-25 my-1" />
            </center>
            <p className="p-0 text-warning fw-bold fs-6">by Góral | Hosted on <a href="https://mikr.us">MIKR.US</a></p>
        </div>
      </div>
    </div>
    <div class="col-lg-0">
    </div>
  </div>
</div>
  );
}
